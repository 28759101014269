<script>
import 'he-tree-vue/dist/he-tree-vue.css';
import {
  Tree, Fold, Check, Draggable,
} from 'he-tree-vue';

export default {
  name: 'CustomTreeView',
  extends: Tree,
  mixins: [Fold, Check, Draggable],
  props: {
    // triggerClass: { default: 'drag-trigger' },
    // draggable: { type: [Boolean, Function], default: false },
    // droppable: { type: [Boolean, Function], default: false },
  },
  data() {
    return {
      treeClass: 'my-tree-view1',
    };
  },
  computed: {
    total() {
      return 10;
    },
  },
  methods: {
    // overrideSlotDefault({ node, path, tree }, original) {
    //   return <div class="node-content">
    //     <span class="mdi mdi-drag-variant mrs drag-trigger drag-btn" />
    //     <a class="mrs fold-btn" onClick={() => tree.toggleFold(node, path)}>
    //       <span class={`mdi ${node.$folded ? 'mdi-menu-up' : 'mdi-menu-down'}`} />
    //     </a>
    //     <input type="checkbox" id="switch"
    //            className="checkbox"/>
    //     <label htmlFor="switch" className="toggle">
    //       <p>OFF ON</p>
    //     </label>
    //     {original()}
    //     <a class="mls" onClick={() => tree.toggleFold(node, path)}>
    //       <span class="mdi mdi-pencil"/>
    //     </a>
    //   </div>;
    //   // <button class="mls" onClick={() => this.removeNodeByPath(path)}>remove</button>
    //   // <button class="mls" onClick={() => this.hideNode(node)}>hidden</button>
    // },
    // blockHeader() {
    //   return <div class="header">
    //     <div>
    //       <button onClick={this.add}>add</button>
    //       <button onClick={this.showHidden} class="mls">show hidden</button>
    //     </div>
    //     <input onKeydown={(e) => e.key === 'Enter' && this.search(e)} placeholder="Search" />
    //   </div>;
    // },
    // blockFooter() {
    //   return <div class="footer">
    //     <i>Nodes count: 111</i>
    //   </div>;
    // },
    // search(e) {
    //   const value = e.target.value || '';
    //   this.walkTreeData((node) => {
    //     this.$set(node, '$hidden', !node.text.includes(value));
    //   });
    // },
  },
};
</script>

<style>
.my-tree-view1 .tree-node{
  padding: 0;
  border: none;
}
.my-tree-view1 .node-content{
  display: flex;
}
</style>
