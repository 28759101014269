<template>
  <v-card
    v-ripple="false"
    class="elevation-2 my-1"
    max-height="400"
    width="230px"
    height="400px"
    justify-center
  >
    <div
      class="product-img mb-2"
      :style="
        `background-image: url(${
          product.imageUrlList && product.imageUrlList.length ? product.imageUrlList[0].url : ''
        })`
      "
    >
      <!--        <v-img-->
      <!--          height="280px"-->
      <!--          max-height="280px"-->
      <!--          style="vertical-align: middle"-->
      <!--          contain-->
      <!--          :src="product.imageUrlList && product.imageUrlList.length ?-->
      <!--            product.imageUrlList[0].preAssignedUrl : ''"-->
      <!--        ></v-img>-->
    </div>

    <v-card-subtitle class="subtitle">Код: {{ product.id }} </v-card-subtitle>
    <v-card-title class="title">
      {{ product.nameRu }}
    </v-card-title>

    <v-card-actions style="padding-left: 0">
      <span class="product-price"
        ><v-icon>mdi-currency-kzt</v-icon>{{ product.price ? product.price : 330 }}</span
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import mixin from "@/views/crm/marketplace/components/mixin";

export default {
  name: "ProductCard",
  mixins: [mixin],
  props: {
    product: {
      type: Object,
      default: () => {}
    },
    value: {
      type: Number
    }
  },
  watch: {
    product: {
      handler(val) {
        if (val && val.imageUrlList && val.imageUrlList.length > 0) {
          if (!("url" in val.imageUrlList[0])) this.getImage(val.imageUrlList[0].path);
        }
      },
      immediate: true,
      deep: true
    }
  },
  data() {
    return {
      loading: false,
      selection: 1
    };
  },
  methods: {
    async getImage(path) {
      const url = await this.getImageByPath(path);
      const obj = this.product.imageUrlList[0];
      obj.url = url;
      this.product.imageUrlList.splice(0, 1, obj);
    }
  }
};
</script>

<style scoped>
.product-view :deep(.v-sheet.v-card ){
  border-radius: 16px;
}

.rounded {
  background: #07051a;
  border-radius: 16px;
}
.box {
  background-color: #eda451;
  width: 32px;
  height: 32px;
}
.title {
  padding: 0 8px;
  font-family: Fira Sans, serif;
  font-style: normal;
  font-weight: 300;
  font-size: 15px !important;
  line-height: 19px;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /*line-height: 16px;     !* fallback *!*/
  max-height: 57px; /* fallback */
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
  margin: 0 8px;
}

.subtitle {
  padding: 0 8px;
  font-family: Fira Sans, serif;
  font-style: normal;
  font-weight: 300;
  font-size: 12px !important;
  line-height: 19px;
  margin: 0 8px;
}
.buttons {
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 10px;
  right: 10px;
}
.product-card {
  border-top: none !important;
  box-shadow: none !important;
}
.product-card:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
}
.product-img {
  padding: 10px 30px;
  width: 100%;
  height: 280px;
  line-height: 330px;
  border-radius: 16px 16px 0 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 500ms ease-in-out;
  background-color: #5e9ca0;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
}
.product-price {
  margin: 0 10px;
  position: absolute;
  bottom: 12px;
}
</style>
