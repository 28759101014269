<template>
  <CustomTreeView
    ref="catalogTree"
    :value="items"
    :ondragend="checkOnDragEnd"
    @drop="onCatalogDrop">
    <!--          eslint-disable-next-line vue/no-unused-vars-->
    <div slot-scope="{node, index, path, tree}">
      <div v-if="node.id > -1" class="d-flex align-center">
        <b
          @click="tree.toggleFold(node, path)"
          class="menu-icon"
        >
          <v-icon
            v-show="node.children && node.children.length">
            {{node.$folded ? 'mdi-menu-right' : 'mdi-menu-down'}}
          </v-icon>
        </b>
        <div v-if="node.id > 0" class="tumbler d-flex flex-grow-1">
          <div class="tumbler__publish">
            <toggle-button
              :width="120"
              :value="node.status !== 'DISABLED'"
              :color="states"
              :switch-color="switchStates"
              :sync="true"
              :labels="labels"
              @change="changeStatus(node.id, $event.value)"
            />
          </div>
          <div class="tumbler__publish"  v-if="node.level === 1">
            <toggle-button
              :width="50"
              :value="node.isAgeLimit"
              :color="states"
              :switch-color="switchStates"
              :sync="true"
              :labels="labelsAge"
              @change="changeAdultStatus(node.id, $event.value)"
            />
          </div>

          <div
            @click="tree.toggleFold(node, path)"
            class="flex-grow-1 tumbler__title">
            <p class="tumbler__name ma-0">
              {{ node.nameRu }}{{ getChildrenLength(node.children) }}
            </p>
            <p class="tumbler__name ma-0">
              {{ node.nameKk }}{{ getChildrenLength(node.children) }}
            </p>
          </div>
          <div class="tumbler_actions">
            <v-btn
              fab
              small
              color="white"
              @click="edit(node.parentId, node.id)"
              :ripple="false"
              class="elevation-0 mx-1"
              width="20px"
              height="20px"
            >
              <v-icon small color="#737373">mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              v-if="(node.children && !node.children.some(x => x.id !== -1)) || !node.children"
              fab
              small
              color="white"
              @click="editFilter(node)"
              :ripple="false"
              class="elevation-0 mx-1"
              width="20px"
              height="20px"
              :disabled="isChief"
            >
              <v-icon small color="#737373">mdi-filter-outline</v-icon>
            </v-btn>
            <v-btn
              v-if="(node.children && !node.children.some(x => x.id !== -1)) || !node.children"
              fab
              small
              color="white"
              @click="deleteCatalog(node.id)"
              :ripple="false"
              class="elevation-0 mx-1"
              width="20px"
              height="20px"
              :disabled="isChief"
            >
              <v-icon small color="#737373">mdi-delete</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
      <v-tooltip v-else-if="node.id === -1" right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            small
            color="white"
            @click="addCatalog(node.parentId)"
            :ripple="false"
            class="add-fab elevation-0"
            width="20px"
            height="20px"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon small color="#737373">mdi-plus</v-icon>
          </v-btn>
        </template>
        <span style="font-size: 12px">Добавить категорию в {{ node.parentName }}</span>
      </v-tooltip>
    </div>
  </CustomTreeView>
</template>

<script>

import { mapActions, mapGetters, mapState } from 'vuex';
import CustomTreeView from '@/components/CustomTreeView.vue';

export default {
  name: 'CatalogTree',
  props: ['items'],
  components: {
    CustomTreeView,
  },
  data() {
    return {
      states: {
        checked: '#4CAF50',
        unchecked: '#EAEAEA',
      },
      switchStates: {
        checked: '#FFFFFF',
        unchecked: '#C4C4C4',
      },
      labels: {
        checked: 'Опубликован',
        unchecked: 'Не опубликован',
      },
      labelsAge: {
        checked: '18+',
        unchecked: '18+',
      },
    };
  },
  computed: {
    ...mapGetters({
      catalogsBase: 'catalogs/catalogsBase',
    }),
    ...mapState(['user']),
    isChief() {
      return this.user.roles.includes('ROLE_CHIEF');
    },
  },
  methods: {
    ...mapActions({
    }),
    checkOnDragEnd(tree, store) {
      /** Для предотвращения создания 4-го уровня. Напр: Двухуроневый каталог в 3-й уровень */
      if ((store.dragNode.children && store.targetPath.length === 3)
        || store.targetPath.length > 3) {
        this.$store.dispatch('tools/setSnackbar', {
          type: 'error',
          message: 'Максимальное количество уровней - 3',
        });
        return false;
      }
      return true;
    },
    changeCatalogStructure(value) {
      this.$emit('changeCatalogStructure', value);
    },
    onCatalogDrop(store) {
      this.changeCatalogStructure(store.targetTree.value);
    },
    addCatalog(parentId) {
      this.$emit('addCatalog', parentId);
    },
    changeStatus(id, value) {
      if (this.isChief) return;
      this.$emit('changeStatus', id, value);
    },
    changeAdultStatus(id, value) {
      if (this.isChief) return;
      this.$emit('changeAdultStatus', id, value);
    },
    deleteCatalog(id) {
      this.$emit('deleteCatalog', id);
    },
    edit(parentId, id) {
      this.$emit('edit', parentId, id);
    },
    editFilter(catalog) {
      this.$emit('editFilter', catalog);
    },
    getChildrenLength(children) {
      return children && children.length > 1 ? ` (${children.length - 1})` : '';
    },
  },
};
</script>

<style scoped lang="scss">
.tumbler {
  max-height: 40px;
  border-radius: 20px;
  background-color: #dbdbdb;
  align-items: center;
  padding: 9px 10px;

&__publish {
   border-radius: 12px;
   padding: 4px 6px;
 }
&__publish.active {
   background-color: #4caf50;
   color: #ffffff;
 }
&__name {
   cursor: pointer;
   font-size: 10px;
   color: #737373;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
 }
&__title {
   /** фиктивная ширина */
   width: 100px;
 }
}
.tumbler:hover {
  background-color: #c9c9c9;
}
.menu-icon {
  min-width: 25px;
  cursor: pointer;
}
.add-fab {
  margin: 4px 0 8px 32px;
  border: 1px solid #DBDBDB
}
.add-fab:hover {
  background-color: #f6f6f6 !important;
  border-color: #f6f6f6 !important;
}
</style>
