import getEnv from '../../../../utils/env';

export default {
  data() {
    return {
      timerId: null,
      catalogId: null,
    };
  },
  methods: {
    async uploadFile({ url, fileName }) {
      const bodyFormData = new FormData();
      const base64ToFile = await this.urlToFile(url, fileName);
      bodyFormData.append('file', base64ToFile);
      return this.uploadImageFile(bodyFormData)
    },

    async uploadImageFile(bodyFormData) {
      const image = await this.$image.post('/image/api/image', bodyFormData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
      return image?.data?.path
    },
    async urlToFile(url, name) {
      const res = await fetch(url);
      const blob = await res.blob();
      return new File([blob], name, { type: blob.type });
    },
    async getImageByPath(path) {
      // eslint-disable-next-line no-return-await
      return await this.$image
        .get(`/image/api/image/${encodeURIComponent(path)}`)
        .then((res) => res.data);
    },
    searchItems($event) {
      if (this.timerId) {
        clearTimeout(this.timerId);
      }
      this.timerId = setTimeout(() => {
        this.$catalogService.get(`/crm-catalogs/search?name=${$event}`).then((res) => {
          this.meta = res;
        });
      }, 500);
    },
    fetchByCategory(id) {
      this.$api.get(`/api/v1/crm/goods/catalog?catalogId=${id}`).then((res) => res.content);
    },
    getCatalogById(id) {
      return this.$catalogService.get(`/catalogs/${id}`).then((res) => [res]);
    },
    generateImageUrl(url) {
      if (url.indexOf('data:') > -1) {
        return url;
      }
      const splitted = url.split('/');
      const name = splitted.splice(splitted.length - 1, 1)[0];
      const path = splitted.join('/');
      return `${getEnv('VUE_APP_URL_MINIO')}/ecom/${path}/M_${name}`;
    },

  },
};
