<template>
  <v-dialog v-model="dialogDeleteFilters" max-width="640px">
    <v-card>
      <v-card-text class="pa-0">
        <v-alert icon="mdi-alert-outline" text type="warning" class="py-6">
          Создание каталога приведет к удалению фильтров родительского каталога <br />
          Вы уверены, что хотите создать новый каталог?
        </v-alert>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn depressed color="primary" text @click="closeDeleteFilter">Cancel</v-btn>
        <v-btn color="primary" rounded @click="deleteFilterConfirm">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DeleteFilterDialog',
  props: ['dialogDeleteFilters'],
  methods: {
    closeDeleteFilter() {
      this.$emit('closeDeleteFilter');
    },
    deleteFilterConfirm() {
      this.$emit('deleteFilterConfirm');
    },
  },
};
</script>

<style scoped>

</style>
