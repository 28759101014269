<template>
  <v-container fluid class="crm-page-height">
    <v-row>
      <v-col class="tree-view fixed-side">
        <catalog-tree
          ref="tree"
          :items="items"
          @addCatalog="addCatalog"
          @edit="edit"
          @editFilter="editFilter"
          @deleteCatalog="deleteCatalog"
          @changeStatus="changeStatus"
          @changeAdultStatus="changeAdultStatus"
          @changeCatalogStructure="changeCatalogStructure"
        />
      </v-col>
      <v-col class="pa-0 flex-grow-1 fixed-side">
        <catalog-view
          :items="items"
          :filterEdit="filterEdit"
          :categories="categories"
          :allFilters="allFilters"
          @closeEditFilter="closeEditFilter"
          :currentCatalogId="currentCatalogId"
          :currentCatalogName="currentCatalogName"
        />
      </v-col>
    </v-row>
    <new-catalog-dialog
      :dialog="dialog"
      :catalog="catalog"
      @save="save"
      @close="close"
    />
    <delete-catalog-dialog
      v-model="dialogDelete"
      @closeDelete="closeDelete"
      @deleteCatalogConfirm="deleteCatalogConfirm"
    />
    <delete-filters-dialog
      :dialogDeleteFilters="dialogDeleteFilters"
      @closeDeleteFilter="closeDeleteFilter"
      @deleteFilterConfirm="deleteFilterConfirm"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import _ from 'lodash';
import {
  foldAll, walkTreeData,
} from 'he-tree-vue';
import CatalogTree from './components/CatalogTree.vue';
import CatalogView from './components/CatalogView.vue';
import NewCatalogDialog from './components/NewCatalogDialog.vue';
import DeleteCatalogDialog from './components/DeleteCatalogDialog.vue';
import DeleteFiltersDialog from './components/DeleteFiltersDialog.vue';

export default {
  name: 'index.vue',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    CatalogTree,
    CatalogView,
    NewCatalogDialog,
    DeleteCatalogDialog,
    DeleteFiltersDialog,
  },
  data() {
    return {
      categories: [],
      categoryFilter: [],
      allFilters: [],
      allFiltersNames: [],
      mainFields: [],
      currentCatalogId: null,
      currentCatalogName: '',
      isLoading: false,
      tree: [],
      types: [],
      items: [],
      dialog: false,
      dialogDelete: false,
      dialogDeleteFilters: false,
      deleteText: '',
      catalog: {
        nameRu: '',
        nameKk: '',
        imageUrl: '',
      },
      parentId: null,
      filterEdit: false,
      catalogTree: true,
      editIndex: -1,
      editId: 0,
      filterList: [],
    };
  },
  computed: {
    ...mapGetters({
      catalogs: 'catalogs/catalogs',
      catalogsBase: 'catalogs/catalogsBase',
      catalogsStructure: 'catalogs/catalogsStructure',
      filterFields: 'catalogs/filterFields',
      catalogFilters: 'catalogs/catalogFilters',
    }),
  },
  mounted() {
    this.init();
  },
  watch: {
    catalogs(val) {
      this.items = [...val];
    },
    catalogsStructure(val) {
      /** @base_ is used because @this is undefined in func
       * $droppable - node property to add sub node
       * $draggable - whether a single node is draggable
       * @lvl - level of nodes (level param will be deprecated in future)
       * foldAll() - after adding values nodes are unfold */
      function joinArrays(arr, base, lvl = 0, parentItem) {
        const addButtonItem = {
          id: -1,
          parentId: parentItem ? parentItem.id : null,
          parentName: parentItem ? parentItem.nameRu : 'корневой каталог',
          $droppable: false,
          $draggable: false,
        };

        const treeCatalogs = arr.map((item) => {
          const catalog = base.find((cat) => cat.id === item.id);
          const params = {
            lvl,
            parentId: parentItem ? parentItem.id : null,
          };
          if (item.children) {
            return Object.assign(item, catalog, params, {
              children: joinArrays(item.children, base, lvl + 1, catalog),
            });
          }
          const treeNode = { ...item, ...catalog, ...params };
          if (lvl < 2) {
            Object.assign(treeNode, { children: [addButtonItem] });
          }
          return treeNode;
        });
        treeCatalogs.push(addButtonItem);
        return treeCatalogs;
      }
      if (!this.items.length) {
        this.items = joinArrays(val, this.catalogsBase);
        foldAll(this.items);
      } else {
        /** Обновляет дерево при переносе каталога в подкаталог,
         * а именно создает (добавляет) кнопку добавления (+) */
        walkTreeData(this.items, (node) => {
          if (node.children && !node.children.some((x) => x.id === -1)) {
            node.children.push({
              id: -1,
              parentId: node.id,
              parentName: node.nameRu,
              $droppable: false,
              $draggable: false,
            });
          }
        });
      }
    },
    dialog(val) {
      // eslint-disable-next-line no-unused-expressions
      val || this.close();
    },
    dialogDeleteFilters(val) {
      // eslint-disable-next-line no-unused-expressions
      val || this.closeDeleteFilter();
    },
    categoryFilter(val) {
      if (val.length > 0) this.getMainFields(val.map((item) => item.id));
    },
    filterFields(val) {
      this.allFilters = this.allFilters.concat(val);
    },
    catalogFilters() {
      this.checkFilters();
    },
    filterEdit(val) {
      if (!val) this.currentCatalogId = null;
    },
  },
  methods: {
    ...mapActions({
      fetchCatalogs: 'catalogs/fetchCatalogs',
      setNewCatalogs: 'catalogs/setNewCatalogs',
      updateCatalog: 'catalogs/updateCatalog',
      // getCatalogStructure: 'catalogs/getCatalogStructure',
      saveCatalogStructure: 'catalogs/saveCatalogStructure',
      updateStatus: 'catalogs/updateStatus',
      updateAdultStatus: 'catalogs/updateAdultStatus',
      getFilters: 'catalogs/getFilters',
      getMainFields: 'catalogs/getMainFields',
      // removeSelectedFilter: 'catalogs/removeSelectedFilter',
      checkFilters: 'catalogs/checkFilters',
    }),
    ...mapMutations('catalogs', ['SET_SELECTED_PRODUCTS']),
    init() {
      this.fetchCatalogs();
      this.fetchCategories();
      this.fetchColors();
      this.fetchCountries();
      this.fetchBrands();
    },
    fetchCategories() {
      this.$api.get('/api/v1/public/categories').then((response) => {
        this.categories = [...response];
      });
    },
    fetchColors() {
      this.$api.get('/api/v1/public/dictionaries?dictionaryCode=color').then((response) => {
        this.allFilters.push({
          id: 'COLOR',
          nameRu: 'Цвет',
          nameKz: 'Цвет1',
          dictionaryValueList: response,
        });
      });
    },
    fetchCountries() {
      this.$api.get('/api/v1/public/dictionaries?dictionaryCode=country').then((response) => {
        this.allFilters.push({
          id: 'COUNTRY',
          nameRu: 'Страна',
          nameKz: 'Страна1',
          dictionaryValueList: response,
        });
      });
    },
    fetchBrands() {
      this.$api.get('/api/v1/crm/catalog/filters/brands').then((response) => {
        this.allFilters.push({
          id: 'BRAND',
          nameRu: 'Бренд',
          nameKz: 'Бренд1',
          dictionaryValueList: response,
        });
      });
    },
    closeEditFilter() {
      this.filterEdit = false;
      this.currentCatalogId = null;
    },
    async addCatalog(parentId) {
      this.filterEdit = false;
      this.parentId = parentId;

      if (parentId) {
        const response = await this.getFilters({ catalogId: parentId });
        if (response === 1) {
          this.dialogDeleteFilters = true;
          return;
        }
      }
      this.dialog = true;
    },
    changeStatus(id, value) {
      this.updateStatus({ id, status: value }).then(async (res) => {
        await walkTreeData(this.items, (node) => {
          if (res && node.id === res.id) {
            Object.assign(node, res);
            return false;
          }
          return node;
        });
        this.items = [...this.items];
      });
    },
    changeAdultStatus(id, value) {
      this.updateAdultStatus({ id, isAgeLimit: value }).then(async (res) => {
        await walkTreeData(this.items, (node) => {
          if (res && node.id === res.id) {
            Object.assign(node, res);
            return false;
          }
          return node;
        });
        this.items = [...this.items];
      });
    },
    deleteCatalog(id) {
      this.filterEdit = false;

      this.currentCatalogId = id;
      this.editIndex = this.catalogsBase.findIndex((el) => el.id === id);
      this.dialogDelete = true;
    },
    edit(parentId, id) {
      this.dialog = true;
      this.parentId = parentId;
      this.editIndex = this.catalogsBase.findIndex((el) => el.id === id);
      this.editId = this.catalogsBase[this.editIndex].id;
      Object.assign(this.catalog, this.catalogsBase[this.editIndex]);
      console.log(this.catalog,'this.catalog')
    },
    async editFilter(catalog) {
      this.currentCatalogName = catalog.nameRu;
      this.currentCatalogId = catalog.id;
      this.SET_SELECTED_PRODUCTS([]);
      await this.getFilters({ catalogId: this.currentCatalogId });
      this.filterEdit = true;
    },
    async deleteCatalogConfirm() {
      const catalogId = this.currentCatalogId;
      await this.deleteFilter(catalogId);
      await this.$catalogService.delete(`/crm-catalogs/${catalogId}`).then(() => {
        walkTreeData(this.items, (node) => {
          if (node.id === catalogId) {
            this.$set(node, '$hidden', true);
            this.$set(node, 'id', -1);
            return false;
          }
          return node;
        });
      });
      this.changeCatalogStructure(this.items);
      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editIndex = -1;
        this.currentCatalogId = null;
      });
    },
    closeDeleteFilter() {
      this.dialogDeleteFilters = false;
    },
    async deleteFilterConfirm() {
      const catalogId = this.parentId;
      await this.deleteFilter(catalogId);
      this.dialogDeleteFilters = false;
      await this.addCatalog(catalogId);
    },
    async deleteFilter(catalogId) {
      await this.$catalogService.delete(`/crm-catalogs/query/${catalogId}`);
    },
    async save(catalog) {
      if (this.editIndex > -1) {
        await this.updateCatalog({
          nameRu: catalog.nameRu,
          nameKk: catalog.nameKk,
          parentId: this.parentId,
          imageUrl: catalog.imageDto?.path,
          id: this.editId,
        }).then((res) => {
          walkTreeData(this.items, (node) => {
            if (res && node.id === res.id) {
              Object.assign(node, res);
            }
          });
          this.items = [...this.items];
        });
      } else {
        await this.setNewCatalogs({
          nameRu: catalog.nameRu,
          nameKk: catalog.nameKk,
          imageUrl: catalog.imageDto?.path,
          parentId: this.parentId,
        }).then((res) => {
          walkTreeData(this.items, (node) => {
            const addBtnArray = [{
              id: -1,
              parentId: res.id,
              parentName: res.nameRu,
              $droppable: false,
              $draggable: false,
            }];
            const newChild = { ...res };
            if (node.lvl < 1) {
              Object.assign(newChild, { children: addBtnArray });
            }
            /** Добавить на предпоследнюю позицию в корень */
            if (this.parentId === null) {
              this.items.splice(this.items.length - 1, 0, newChild);
              return false;
            }
            if (res && node.id === this.parentId) {
              /** Добавить на предпоследнюю позицию новый элемент */
              node.children.splice(node.children.length - 1, 0, newChild);
              return false;
            }
            return node;
          });
        });
      }
      /** ref from CustomTreeView(tree) in <catalog-tree>(catalogTree)
       * timeout for update value */
      this.changeCatalogStructure(this.$refs.tree.$refs.catalogTree.value);
      this.close();
    },
    changeCatalogStructure(value) {
      function extract(array) {
        return array.map((item) => {
          const obj = _.pick(item, ['id', 'children']);
          if (obj.children && obj.children.length > 0) { // Array.isArray(obj.children)
            return { ...obj, children: extract(obj.children) };
          }
          delete obj.children;
          return obj;
        }).filter((x) => (x.id !== -1));
      }
      this.saveCatalogStructure(extract(value));
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.catalog = {};
        this.editIndex = -1;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.tree-view {
  width: 460px;
  max-width: 460px;
  background-color: #e9e9e9;
  height: 100%;
  //height: -moz-available;          /* WebKit игнор. */
  //height: -webkit-fill-available;  /* Mozilla игнор. */
  //height: fill-available;
  overflow: scroll;
}
.fixed-side {
  overflow: scroll;
  height: 100%;
}
.right-side {
  height: calc(100vh - 190px);
}
.theme--light.v-btn:hover::before {
  opacity: 0;
}
</style>
