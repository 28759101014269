<template>
  <v-dialog v-model="show" max-width="640px">
    <v-card>
      <v-card-text class="pa-0">
        <v-alert icon="mdi-alert-octagon-outline" text type="error" class="py-6">
          Вы уверены, что хотите удалить каталог?
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn depressed color="primary" text @click="closeDelete">Cancel</v-btn>
        <v-btn color="primary" rounded @click="deleteCatalogConfirm">OK</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DeleteCatalogDialog',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    closeDelete() {
      this.show = false;
      // this.$emit('closeDelete');
    },
    deleteCatalogConfirm() {
      this.$emit('deleteCatalogConfirm');
    },
  },
};
</script>

<style scoped>

</style>
