<template>
  <div>
    <div v-if="!filterEdit">
      <v-card>
        <v-card-title class="elevation-3 pa-0 sticky-header">
          <v-tabs v-model="tab" height="72" slider-color="rgba(51, 51, 51, 0.5)">
            <v-tab>На русском</v-tab>
            <v-tab>На казахском</v-tab>
          </v-tabs>
        </v-card-title>
        <v-card-text style="background-color: #DEDEDE" class="pt-6 pl-6 pr-0 right-side">
          <v-container style="background-color: #FFFFFF;">
            <v-row>
              <v-col class="catalog-view">
                <v-list dense>
                  <v-list-item-group v-model="selectedItem">
                    <template v-for="item in items.filter(el => el.id > -1)">
                      <v-list-item :key="item.id" @click="showChildren(item)">
                        <template v-slot:default="{ active }">
                          <v-list-item-avatar v-if="item.imageDto
                          && item.imageDto.path">
                            <v-img :src="computedUrl(item)"></v-img>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title v-text="tab === 0 ? item.nameRu : item.nameKk">
                            </v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-icon v-if="active" color="grey lighten-1">
                              mdi-chevron-right
                            </v-icon>
                          </v-list-item-action>
                        </template>
                      </v-list-item>
                    </template>
                  </v-list-item-group>
                </v-list>
              </v-col>
              <v-col class="flex-grow-1">
                <v-container v-if="currentCatalog">
                  <v-row>{{ tab === 0 ? currentCatalog.nameRu : currentCatalog.nameKk }}</v-row>
                  <v-row
                    v-for="item in currentCatalog.children ?
                        currentCatalog.children.filter(el => el.id > -1) : []"
                    :key="item.id"
                  >
                    <v-col>
                      <v-container>
                        <v-row dense>
                          <v-col style="max-width: 48px;">
                            <v-img
                              v-if="item.imageDto
                              && item.imageDto.path"
                              width="40"
                              height="40"
                              class="border-rad"
                              :src="computedUrl(item)"
                              :lazy-src="require('@/assets/logo4.svg')"
                            />
                          </v-col>
                          <v-col class="flex-grow-1" align-self="center">
                            <span>{{ tab === 0 ? item.nameRu : item.nameKk }}</span>
                          </v-col>

                        </v-row>
                        <v-row
                          v-for="item2 in
                              item.children ? item.children.filter(el => el.id > -1) : []"
                          :key="item2.id"
                        >
                          <v-row class="pl-8">
                            <v-col style="max-width: 64px;">
                              <v-img
                                v-if="item2.imageDto
                                && item2.imageDto.path"
                                width="40"
                                height="40"
                                class="border-rad"
                                :src="computedUrl(item2)"
                                :lazy-src="require('@/assets/logo4.svg')"
                              />
                            </v-col>
                            <v-col class="flex-grow-1 pa-2">
                              <span>{{ tab === 0 ? item2.nameRu : item2.nameKk }}</span>
                            </v-col>
                          </v-row>
                        </v-row>
                      </v-container>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </div>
    <div v-else class="right-side" style="height: 100%">
      <v-card style="position: relative" height="100%">
        <v-card-title class="elevation-3 sticky-header">
          Настройка фильтра
          <v-switch class="ml-5" v-model="productView" color="primary" label="Товары" />
          <v-spacer></v-spacer>
          <v-btn icon @click="closeEditFilter">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="elevation-4"></v-divider>
        <v-card-text class="pa-0">
          <v-container>
            <v-row style="min-height: calc(100vh - 300px);">
              <v-col v-if="!productView"  class="d-flex flex-column filters pa-6">
                <v-row class="px-2">
                  <v-col>
                    <h3 style="color: #333333">{{ currentCatalogName }}</h3>
                  </v-col>
                </v-row>
                <v-row class="mb-4 py-2">
                  <v-col>
                    <v-autocomplete
                      v-model="category"
                      label="Номенклатурная категория"
                      :persistent-placeholder="true"
                      :items="categories"
                      return-object
                      item-text="nameRu"
                      item-value="id"
                      @change="setFilter(category, 'CATEGORY')"
                    >
                      <template v-slot:item="{item, attrs, on}">
                        <v-list-item v-on="on" v-bind="attrs" >
                          <span>{{item.nameRu}} - &nbsp;
                          <span class='grey--text'>{{ showParent(item.parentId) }}</span>
                          </span>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                    <div v-if="catalogFilters['CATEGORY']">
                      <v-chip
                        v-for="(chip, index) in catalogFilters['CATEGORY'][0].valueList"
                        :key="index"
                        close
                        close-icon="mdi-close"
                        text-color="white"
                        @click:close="handleDeleteChipFilter(chip, 'CATEGORY')"
                        class="mr-1 mb-1 selector"
                      >
                        {{ getCategoryName(chip) }}
                      </v-chip>
                    </div>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="py-2">
                  <v-col>
                    <v-autocomplete
                      v-model="selected"
                      :items="allFilters"
                      label="Фильтры"
                      :persistent-placeholder="true"
                      return-object
                      item-text="nameRu"
                      @change="addFilter(selected)"
                    />
                    <div>
                      <v-chip
                        v-for="(chip, index) in selectedFilters.filter(i => i !=='NOMENCLATURE_ID')"
                        :key="index"
                        close
                        close-icon="mdi-close"
                        @click:close="handleDeleteChip(index, selectedFilters)"
                        class="mr-1 mb-1 white-text selector"
                      >
                        {{ getFilter(chip).nameRu }}
                      </v-chip>
                    </div>
                  </v-col>
                </v-row>

                <v-row class="py-2"
                       v-for="filter in selectedFilters.filter(i => i !== 'NOMENCLATURE_ID')"
                       :key="filter.name">
                  <v-col>
                    <v-autocomplete
                      v-if="getFilter(filter).dictionaryValueList"
                      v-model="chosenFilters[filter]"
                      :items="getFilter(filter).dictionaryValueList"
                      :label="getFilter(filter).nameRu"
                      return-object
                      :persistent-placeholder="true"
                      item-text="nameRu"
                      @change="setFilter(chosenFilters[filter], filter, getFilter(filter).type)"
                    />
                    <v-text-field
                      v-else
                      append-icon="mdi-plus"
                      v-model="chosenFilters[filter]"
                      :label="getFilter(filter).nameRu"
                      :persistent-placeholder="true"
                      @click:append="
                            setFilter(chosenFilters[filter], filter, getFilter(filter).type)"
                      @keypress.enter="
                            setFilter(chosenFilters[filter], filter, getFilter(filter).type)"
                    />
                    <div v-if="catalogFilters[filter]">
                      <v-chip
                        v-for="(chip, index) in catalogFilters[filter][0].valueList"
                        :key="index"
                        close
                        color="#0EADAE"
                        close-icon="mdi-close"
                        @click:close="handleDeleteChipFilter(chip, filter)"
                        class="mr-1 mb-1 white-text selector"
                      >
                        {{ getFilterDictValueName(filter, chip) }}
                      </v-chip>
                    </div>
                    <div
                      v-if="catalogFilters[`FIELD_${filter}`] &&
                      getFilter(filter).dictionaryValueList"
                    >
                      <v-chip
                        v-for="(chip, index) in catalogFilters[`FIELD_${filter}`][0].valueList"
                        :key="index"
                        close
                        color="#9EAD70"
                        close-icon="mdi-close"
                        @click:close="handleDeleteChipFilter(chip, filter)"
                        class="mr-1 mb-1 white-text selector"
                      >
                        {{ getFilterDictValueName(filter, chip) }}
                      </v-chip>
                    </div>
                    <div
                      v-if="catalogFilters[`FIELD_${filter}`] &&
                      !getFilter(filter).dictionaryValueList"
                    >
                      <v-chip
                        v-for="(chip, index) in catalogFilters[`FIELD_${filter}`][0].valueList"
                        :key="index"
                        close
                        color="#9EADAE"
                        close-icon="mdi-close"
                        @click:close="handleDeleteChipFilter(chip, filter)"
                        class="mr-1 mb-1 white-text selector"
                      >
                        {{ chip }}
                      </v-chip>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col v-else class="d-flex flex-column filters pa-6">
                <v-autocomplete
                    v-model="category"
                    label="Номенклатурная категория"
                    :persistent-placeholder="true"
                    :items="categories"
                    return-object
                    item-text="nameRu"
                    item-value="id"
                    @change="productSetNewFilter"
                >
                  <template v-slot:item="{item, attrs, on}">
                    <v-list-item v-on="on" v-bind="attrs" >
                          <span>{{item.nameRu}} - &nbsp;
                          <span class='grey--text'>{{ showParent(item.parentId) }}</span>
                          </span>
                    </v-list-item>
                  </template>
                </v-autocomplete>
                <v-checkbox :value="selectAll" @change="onChange" label="Выбрать все"
                            :disabled="Object.keys(category).length === 0" />
                <v-autocomplete v-model="searchProduct" label="ID"
                                :items="productList" :search-input.sync="productId"
                                no-filter
                                :loading="isLoading"
                                multiple
                                @blur="onSearchChange"
                                return-object item-text="nameRu"

                />
                <ProductRow :products="selectedProducts" @delete="selectProduct"/>
                <v-btn @click="saveProductsList" color="primary"> Сохранить</v-btn>
              </v-col>
              <v-col class="filtered-items flex-grow-1">
                <v-container>
                  <v-row>
                    <v-text-field
                      placeholder="Поиск по коду или наименованию товара"
                      prepend-inner-icon="mdi-magnify"
                      v-model="productSearchText"
                    >

                    </v-text-field>
                  </v-row>
                  <v-row
                    style="min-height: 88px"
                  >
                    <div v-show="!orderedProducts.length" class="drag-drop-zone">
                      <div class="d-flex" style="gap: 10px;">
                        <v-icon>
                          mdi-drag-variant
                        </v-icon>
                        <span>
                          Перетащите товары в эту область, чтобы задать приоритет
                        </span>

                      </div>
                    </div>
                    <draggable
                               group="products"
                               :list="orderedProducts"
                               style="min-height: 100px; gap: 10px; width: 100%"
                               class="d-flex flex-wrap"
                               @change="log"
                    >
                      <product-card
                        v-for="product in orderedProducts"
                        :key="product.id"
                        :product="product" />

                    </draggable>

                  </v-row>
                  <v-divider class="mt-9" />
                  <v-row
                    class="mt-5"
                    style="min-height: 88px"
                  >
                    <draggable
                      :list="notOrderedProducts"
                      group="products"
                      handle=".handle"
                      class="flex-wrap d-flex " style="gap: 10px; width: 100%"
                      @change="log"
                    >
                      <div v-for="i in notOrderedProducts" :key="i.id" class="product-view handle">
                        <v-btn icon @click="selectProduct(i)"
                               v-if="productView"
                         :class="['check-button',{'selected': isSelected(i)}]">
                          <v-icon v-if="isSelected(i)">
                            mdi-checkbox-marked-circle-plus-outline
                          </v-icon>
                          <v-icon v-else>
                            mdi-checkbox-blank-circle-outline
                          </v-icon>

                        </v-btn>
                        <v-fade-transition>
                          <product-card :product="i" />
                        </v-fade-transition>
                      </div>
                    </draggable>
                    <div v-show="!notOrderedProducts.length" class="drag-drop-zone">
                      <div class="d-flex" style="gap: 10px;">
                        <v-icon>
                          mdi-drag-variant
                        </v-icon>
                        <span>
                          Перетащите товары в эту область
                        </span>

                      </div>
                    </div>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import ProductCard from '@/components/ProductCard.vue';
import getEnv from '@/utils/env';
import ProductRow from './ProductRow.vue';
import draggable from 'vuedraggable';

export default {
  name: 'CatalogView',
  props: [
    'items',
    'categories',
    'allFilters',
    'filterEdit',
    'currentCatalogId',
    'currentCatalogName',
  ],
  components: {
    ProductRow,
    ProductCard,
    draggable,
  },
  data() {
    return {
      tab: null,
      selectedItem: null,
      category: {},
      selected: {},
      currentCatalog: null,
      chosenFilters: {},
      chosenFiltersValues: [],
      currentItems: [],
      productView: false,
      productCategory: null,
      selectedProducts: [],
      selectAll: false,
      productId: null,
      productList: [],
      isLoading: false,
      timeout: null,
      searchProduct: null,
      orderedProducts: [],
      orderedProductsStore: [],
      productSearchText: '',
    };
  },
  computed: {
    ...mapGetters({
      products: 'catalogs/products',
      catalogFilters: 'catalogs/catalogFilters',
      selectedFilters: 'catalogs/selectedFilters',
      nomenclature: 'catalogs/nomenclature',
    }),
    ...mapState('catalogs', ['fetchedSelectedProducts']),
    notOrderedProducts() {
      const ids = this.orderedProducts.map(product => product.id)
      let filteredProducts = this.products.filter(product => !ids.includes(product.id))
      if (this.productSearchText) {
        filteredProducts = filteredProducts.filter(product => product.nameRu.toLowerCase().indexOf(this.productSearchText.toLowerCase()) > -1 || product.id.toString().includes(this.productSearchText))
      }
      return filteredProducts
    },
  },
  watch: {
    products: {
      handler(val) {
        if (val?.length) {
          this.getOrderedProductsInCatalog()
        }
      }
    },
    fetchedSelectedProducts: {
      handler(val) {
        if (val && val.length > 0) {
          this.selectedProducts = val;
        } else {
          this.selectedProducts = [];
        }
      },
    },
    nomenclature(val) {
      this.productView = val;
    },
    currentCatalogId: {
      handler(val, oldVal) {
        if (val && oldVal && val !== oldVal) {
          this.selectedProducts = [];
          this.orderedProducts.splice(0)
          this.orderedProductsStore.splice(0)
          this.productView = this.nomenclature;
        }
      },
    },
    productId(val) {
      if (val) {
        if (this.timeout) {
          clearTimeout(this.timeout);
        }

        this.timeout = setTimeout(() => {
          // Items have already been requested
          if (this.isLoading) return;

          this.isLoading = true;

          this.$api.get(`/api/v1/public/goods/nomenclature-list?productNomenclatureIdList=${this.productId}`)
            .then((res) => {
              this.$nextTick(() => {
                this.productList = [...res];
              });
            })
          // eslint-disable-next-line no-return-assign
            .finally(() => (this.isLoading = false));
        }, 500);
      }
    },
  },
  methods: {
    ...mapActions({
      addSelectedFilter: 'catalogs/addSelectedFilter',
      removeFilterFromList: 'catalogs/removeFilterFromList',
      setNewFilter: 'catalogs/setNewFilter',
      removeFilter: 'catalogs/removeFilter',
      saveFilters: 'catalogs/saveFilters',
      checkFilters: 'catalogs/checkFilters',
      productCheckFilters: 'catalogs/productCheckFilters',
      productSaveFilters: 'catalogs/productSaveFilters',
      productCheckIdFilters: 'catalogs/productCheckIdFilters',

    }),
    getFilter(chip) {
      const filter = this.allFilters.find((el) => el.id === chip);
      return filter || { nameRu: 'NONE', dictionaryValueList: [] };
    },
    getCategoryName(chip) {
      const cat = this.categories.find((el) => el.id === +chip);
      // eslint-disable-next-line no-nested-ternary
      return cat ? (this.tab ? cat.nameRu : cat.nameKk) : 'Не найден';
    },
    getFilterDictValueName(filter, chip) {
      const findFilter = this.allFilters.find((el) => el.id === filter);
      if (!findFilter) {
        return chip;
      }
      const dictValue = findFilter.dictionaryValueList
        .find((el) => (el.id ? el.id === chip : el.normalize() === chip.normalize()));
      const name = dictValue && dictValue.nameRu ? dictValue.nameRu : dictValue;
      return name || chip;
    },
    closeEditFilter() {
      this.$emit('closeEditFilter');
    },
    showChildren(item) {
      this.currentCatalog = item;
    },
    productSetNewFilter(val) {
      if (val) {
        this.productCheckFilters([val.id]);
      }
    },
    setFilter(item, filterName, type) {
      const code = filterName;
      const value = item.id || +item || item;
      this.setNewFilter({
        filter: { code, value },
      });
      this.saveFilters({ catalogId: this.currentCatalogId })
        .catch(({ response }) => {
          this.category = {};
          let message = 'Не удалось добавить категорию. ';
          if (response && response.data && response.data.error) {
            message = message.concat(response.data.error);
          }
          this.$store.dispatch('tools/setSnackbar', {
            type: 'error',
            message,
          });
          this.removeFilter({
            filter: { code, value },
          });
        });
      this.category = {};
      const stringTypes = ['STRING_LIST', 'NUMBER', 'DECIMAL'];
      this.chosenFilters[filterName] = stringTypes.some((strType) => strType === type) ? '' : {};
    },
    selectFilters(filterName, value, text) {
      if (!this.chosenFiltersValues[filterName]) {
        this.chosenFiltersValues[filterName] = [];
      }

      if (this.chosenFiltersValues[filterName].indexOf(value) === -1) {
        this.chosenFiltersValues[filterName].push(value);
      }
      this.chosenFilters[filterName] = text ? null : {};
    },
    /** Удаляет категорию */
    handleDeleteChip(index, store) {
      this.removeFilterFromList({ filter: store[index] });
      store.splice(index, 1);
      this.saveFilters({ catalogId: this.currentCatalogId });
    },
    addFilter(item) {
      this.addSelectedFilter({ filter: item.id });
      this.selected = {};
    },
    /** Удаляет значение категории */
    handleDeleteChipFilter(chip, filterName) {
      this.removeFilter({
        filter: {
          code: typeof filterName === 'string' ? filterName : `FIELD_${filterName}`,
          value: chip,
        },
      });
      this.saveFilters({ catalogId: this.currentCatalogId });
    },
    computedUrl(item) {
      const splitted = item.imageDto.path.split('/');
      const name = splitted.splice(splitted.length - 1, 1)[0];
      const path = splitted.join('/');
      return `${getEnv('VUE_APP_URL_MINIO')}/ecom/${path}/M_${name}`;
    },
    showParent(parentId) {
      return this.categories.find((el) => el.id === parentId)?.nameRu || '';
    },
    selectProduct(product, remove = true) {
      const findIndex = this.selectedProducts.findIndex((i) => i.id === product.id);
      if (findIndex > -1) {
        if (remove) {
          this.selectedProducts.splice(findIndex, 1);
        }
      } else {
        this.selectedProducts.push(product);
      }
      this.productCheckIdFilters(this.selectedProducts.map((i) => i.id));
    },
    isSelected(product) {
      return this.selectedProducts.findIndex((i) => i.id === product.id) > -1;
    },
    saveProductsList() {
      try {
        const res = this.productSaveFilters({
          payload: this.selectedProducts.map((i) => i.id),
          catalogId: this.currentCatalogId,
        });
        if(res){

          this.$toast.success('Сохранено');
        }
      }catch (e) {
        console.log(e,'???')
      }

    },
    onChange() {
      this.products.forEach((i) => {
        this.selectProduct(i, false);
      });
      this.selectAll = this.checkAllProducts();
    },
    checkAllProducts() {
      // eslint-disable-next-line max-len
      return this.products.every((i) => this.selectedProducts.findIndex(((j) => j.id === i.id)) > -1);
    },
    onSearchChange() {
      if (this.searchProduct && this.searchProduct.length > 0) {
        this.searchProduct.forEach((i) => {
          this.selectProduct(i, false);
        });

        this.productId = null;
        this.productList = [];
        this.searchProduct = [];
      }
    },
    log(evt) {
      if (evt?.added) {
        const {newIndex: index, element} = evt.added
        const isAddToOrderedStore = this.orderedProducts.find(product => product.id === element.id)
        if (!isAddToOrderedStore) {
          const idInOrder = this.orderedProductsStore.find(product => product.productId === element.id)
          this.removeFromOrderProductInCatalog(idInOrder.id)
        } else {
          this.addOrderProductInCatalog({index: index + 1, id: element.id})
        }
      } else if (evt?.moved) {
        const {newIndex: index, element} = evt.moved
        const idInOrder = this.orderedProductsStore.find(product => product.productId === element.id)
        if (idInOrder) {
          this.moveOrderProductInCatalog(element.id, index + 1)
        }
      } else if (evt?.removed) {
        const {element} = evt.removed
        if (!element && this.notOrderedProducts.length === 1) {
          this.addOrderProductInCatalog({index: this.orderedProducts.length, id: this.notOrderedProducts[0].id})
        }

      }
    },
    async getOrderedProductsInCatalog() {
      const response = await this.$catalogService.get(`/crm-catalogs/main-page/catalog-product/${this.currentCatalogId}`)
      this.orderedProductsStore = [...response]
      const ids = response?.map(el => el.productId) || []
      this.orderedProducts.splice(0)
      ids.forEach(id => {
        const product = this.products.find(product => product.id === id)
        if (product) {
          this.orderedProducts.push(product)
        }
      })
    },
    async addOrderProductInCatalog({index, id: productId}) {
      try {
        await this.$catalogService.post(`/crm-catalogs/main-page/catalog-product`, {
          catalogId: this.currentCatalogId,
          index,
          productId
        })
      } finally {
        await this.getOrderedProductsInCatalog()
      }
    },
    async removeFromOrderProductInCatalog(id) {
      try {
        await this.$catalogService.delete(`/crm-catalogs/main-page/catalog-product/${id}`)
      } finally {
        await this.getOrderedProductsInCatalog()
      }
    },
    async moveOrderProductInCatalog(productId, index) {
      await this.$catalogService.put(`/crm-catalogs/main-page/catalog-product/change-index-product`, {
        catalogId: this.currentCatalogId,
        productId,
        index
      })
      await this.getOrderedProductsInCatalog()
    },
  },
};
</script>

<style scoped lang="scss">
.catalog-view {
  width: 250px;
  max-width: 250px;
  border-right: 1px solid rgba(89, 89, 89, 0.2);
  padding: 0;

  .v-list {
    padding: 0;
  }
  .v-list-item--dense,
  .v-list--dense .v-list-item {
    min-height: 48px;
  }
  .v-list-item__title {
    color: rgba(89, 89, 89, 0.8);
  }
}

.product-view {
  position: relative;
  &:hover {
    .check-button {
      background: #FFFFFF;
      opacity: 1;
    }
  }
}

.check-button{
  position: absolute;
  right: 0;
  top: 4px;
  background-color: #dadada;
  opacity: 0;
  z-index: 1;

  &.selected {
    background: #FFFFFF;
    opacity: 1;
  }
}
.sticky-header {
  position: sticky;
  top: 0;
  z-index: 3;
  background-color: white;
}
.filters {
  width: 388px;
  max-width: 388px;
  background-color: #ffffff;
  align-self: start;
}
.filtered-items {
  background-color: #dadada;
  position: relative;
  min-height: calc(100vh - 300px);
}
.v-tab {
  text-transform: none;
  color: #333333;
}
.border-rad {
  border-radius: 10px;
}
.selector {
  height: auto;
  white-space: normal;
  padding: 4px 10px;
  background-image: linear-gradient(#cadadc, #648a8d) !important;
  background-size: cover !important;
  background-position: center !important;
  background-attachment: fixed !important; /* <- here it is */
}

.drag-drop-zone {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 88px;
  width: 100%;
  border-top: 1px solid #A7A9AC;
  border-bottom: 1px solid #A7A9AC;
  padding: 32px 0;
  margin-bottom: 16px;
  z-index: 0;
  left: 0;
}

</style>
