<template>
  <v-dialog v-model="dialog" width="640px" @keydown.esc="close" @click:outside="close">
    <v-card>
      <v-card-title> Каталог </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          @submit="save"
        >
          <v-text-field
            label="Наименование"
            :persistent-placeholder="true"
            v-model="currentCatalog.nameRu"
            :rules="[v => !!v || 'Укажите название']"
            required
          />
          <v-text-field
            label="Наименование на казахском"
            :persistent-placeholder="true"
            v-model="currentCatalog.nameKk"
            :rules="[v => !!v || 'Укажите название']"
            required
          />
        </v-form>
        <CmImageUploader @set="handleImage" v-if="!showImage"/>
        <v-img v-if="showImage"
               :src="computedUrl(currentCatalog)" alt="Каталог">
          <v-btn text style="position: absolute; right: 0; top:0" :disabled="isChief"
                 @click="removeImagePath" fab>
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-img>
      </v-card-text>
      <v-card-actions>
        <v-row class="mb-2">
          <v-col cols="3" class="d-flex">
            <v-btn depressed block color="primary" text @click="close"
                   :loading="loading">Отменить</v-btn>
            <v-btn block color="primary" class="mx-2" rounded @click="save" :disabled="isChief"
                   :loading="loading">Сохранить</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import getEnv from '@/utils/env';
import { mapState } from 'vuex';

export default {
  name: 'NewCatalogDialog',
  props: ['dialog', 'catalog'],
  data() {
    return {
      valid: true,
      currentCatalog: {},
      loading: false,
    };
  },
  watch: {
    dialog: {
      handler(val) {
        if (val) {
          this.currentCatalog = JSON.parse(JSON.stringify(this.catalog));
        }
      },
    },
  },
  computed: {
    ...mapState(['user']),
    isChief() {
      return this.user.roles.includes('ROLE_CHIEF');
    },
    showImage() {
      if (this.currentCatalog && this.currentCatalog.imageDto
          && this.currentCatalog.imageDto.path) {
        return true;
      }

      return false;
    },
  },
  methods: {
    close() {
      this.image = null;
      this.currentCatalog = {};
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.$emit('close');
    },
    save(e) {
      if (e) e.preventDefault();
      if (!this.validate()) return;
      this.$emit('save', this.currentCatalog);
    },
    async uploadImage(image) {
      this.loading = true;
      const bodyFormData = new FormData();
      const base64ToFile = await this.urlToFile(image);
      bodyFormData.append('file', base64ToFile);
      const res = await this.$image.post(
        '/image/api/image',
        bodyFormData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      this.currentCatalog.imageDto = res.data;
      this.loading = false;
    },
    async urlToFile({ url, file }) {
      const res = await fetch(url);
      const blob = await res.blob();
      return new File([blob], file.name, { type: file.type });
    },
    validate() {
      return this.$refs.form.validate();
    },
    async handleImage(image) {
      if (this.isChief) return;
      await this.uploadImage(image);
    },
    computedUrl(item) {
      const splitted = item.imageDto.path.split('/');
      const name = splitted.splice(splitted.length - 1, 1)[0];
      const path = splitted.join('/');
      return `${getEnv('VUE_APP_URL_MINIO')}/ecom/${path}/M_${name}`;
    },
    removeImagePath() {
      this.$set(this.currentCatalog, 'imageDto', {});
    },
  },
};
</script>

<style scoped>

</style>
