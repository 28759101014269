<template>
  <draggable
      class="product-row"
      :list="currentProducts"
  >
    <div
        v-for="product in currentProducts"
        :key="product.id"
        class="product-row-item"
    >
      <v-btn icon x-small class="close"
        @click="$emit('delete', product)"
      >
        <v-icon size="small">
          mdi-close
        </v-icon>
      </v-btn>
      <v-img
        height="80"
        width="50"
        max-height="80"
        style="vertical-align: middle"
        contain
        :src="generateUrl(product.imageUrlList)"

      ></v-img>
    </div>
  </draggable>
</template>

<script>
import draggable from 'vuedraggable';

export default {
  name: 'ProductRow',
  props: {
    products: {
      type: Array,
    },
  },
  components: {
    draggable,
  },
  data() {
    return {
      currentProducts: [],
    };
  },
  watch: {
    products: {
      handler(val) {
        this.currentProducts = val;
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    generateUrl(image) {
      if (image && image.length > 0) {
        if (image[0].url) {
          return image[0].url;
        }

        if (image[0].preAssignedUrl) {
          return image[0].preAssignedUrl;
        }
      }
      return '';
    },
  },
};
</script>

<style lang="scss">
.product-row {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  height: 100%;
  &-item {
    padding: 0 5px;
    position: relative;
  }
  .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    background: #FFFFFF;
  }
}

</style>
